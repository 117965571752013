import { Transition } from "react-transition-group";
import Breadcrumb from "../../base-components/Breadcrumb";
import { useState, useEffect, createRef } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  selectCompactMenu,
  setCompactMenu as setCompactMenuStore,
} from "../../stores/basicSlices/compactMenuSlice";
import { useAppDispatch, useAppSelector } from "../../stores";
import { FormattedMenu, linkTo, nestedMenu, enter, leave } from "./side-menu";
import Lucide from "../../base-components/Lucide";
import users from "../../fakers/users";
import clsx from "clsx";
import SimpleBar from "simplebar";
import { Menu } from "../../base-components/Headless";
import QuickSearch from "./QuickSearch";
import SwitchAccount from "../../components/SwitchAccount";
import NotificationsPanel from "./NotificationsPanel";
import ActivitiesPanel from "../../components/ActivitiesPanel";
import logowhite from "../../assets/images/brand/logo-white.webp";
import RecieptIcon from "../../assets/images/icon/receipt.svg";
import { logout, selectAuthUser } from "src/stores/auth";
import { useAppRoutes } from "../../router/constants";
import { menus } from "./constants";
import Card from "src/components/Card";
import { selectNotifications } from "src/stores/notification";
import LoadingIcon from "src/base-components/LoadingIcon";
import {
  checkPermission,
  isAdmin,
  isManager,
  isManagerOrAdmin,
  isSuperAdmin,
} from "src/helpers";
import { queryAPIUrls } from "src/common/apiActions";
import Button from "src/base-components/Button";
import QueryPanel from "./QueryQueuesPanel";
import QueryQueuesPanel from "./QueryQueuesPanel";
import { selectQueus } from "src/stores/queue";
import SlideoverModal from "src/components/SlideoverModal";
import Tippy from "src/base-components/Tippy";

function Main() {
  const routes = useAppRoutes();
  const dispatch = useAppDispatch();
  // const compactMenu = useAppSelector(selectCompactMenu);
  const compactMenu = false;
  const setCompactMenu = (val: boolean) => {
    dispatch(setCompactMenuStore(val));
  };
  const [quickSearch, setQuickSearch] = useState(false);
  const [switchAccount, setSwitchAccount] = useState(false);
  const [notificationsPanel, setNotificationsPanel] = useState(false);
  const [queryPanel, setQueryPanel] = useState(false);
  const [activitiesPanel, setActivitiesPanel] = useState(false);
  const [compactMenuOnHover, setCompactMenuOnHover] = useState(false);
  const [activeMobileMenu, setActiveMobileMenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [formattedMenu, setFormattedMenu] = useState<
    Array<FormattedMenu | string>
  >([]);
  const notificationsBadge = useAppSelector(selectNotifications)?.badge;
  const queuesBadge = useAppSelector(selectQueus)?.length;

  const authUser = useAppSelector(selectAuthUser);
  const sideMenus = menus(authUser);
  const sideMenu = () => nestedMenu(sideMenus, location);
  const scrollableRef = createRef<HTMLDivElement>();
  const [currentScreen, setCurrentScreen] = useState<{
    title: string | React.ReactNode;
    path: string;
  }>({ title: "", path: "" });
  const [crmVersion, setCrmVersion] = useState<any>("");
  const [raiseTicket, setRaiseTicket] = useState<boolean>(false);

  useEffect(() => {
    const setingVerion = setTimeout(() => {
      setCrmVersion(process.env.REACT_APP_CRM_VERSION);
    }, 500);
    return () => clearTimeout(setingVerion);
  }, []);

  const toggleCompactMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setCompactMenu(!compactMenu);
  };

  const compactLayout = () => {
    if (window.innerWidth <= 1600) {
      setCompactMenu(true);
    }
  };

  const requestFullscreen = () => {
    const el = document.documentElement;
    if (el.requestFullscreen) {
      el.requestFullscreen();
    }
  };

  useEffect(() => {
    if (scrollableRef.current) {
      new SimpleBar(scrollableRef.current);
    }
    let currentScreenTitle = routes?.find(
      (ele) =>
        ele?.path?.replace("/", "") === location?.pathname?.replace("/", "")
    );
    if (!currentScreenTitle) {
      let childrenRoutes: any[] = [];
      routes.map((item) => {
        if (item?.children && item?.children?.length > 0) {
          childrenRoutes?.push(...item?.children);
        }
      });
      currentScreenTitle = childrenRoutes?.find(
        (ele) =>
          ele?.path?.replace("/", "") == location?.pathname?.replace("/", "")
      );
    }

    if (!currentScreenTitle && location?.pathname?.includes("/query-detail")) {
      currentScreenTitle = {
        title: (
          <Breadcrumb className="flex-1 hidden xl:block">
            <Breadcrumb.Link to={"/queries"} active={false}>
              Queries
            </Breadcrumb.Link>
            <Breadcrumb.Link to={"#"} active={true}>
              Query
            </Breadcrumb.Link>
          </Breadcrumb>
        ),
      };
    }
    if (location?.pathname?.includes("/create-query")) {
      currentScreenTitle = {
        title: (
          <Breadcrumb className="flex-1 hidden xl:block">
            <Breadcrumb.Link to={"/queries"} active={false}>
              Queries
            </Breadcrumb.Link>
            <Breadcrumb.Link to={"#"} active={true}>
              Create Query
            </Breadcrumb.Link>
          </Breadcrumb>
        ),
      };
    }
    setCurrentScreen({
      title: currentScreenTitle?.title || "",
      path: currentScreenTitle?.path || "",
    });
    setFormattedMenu(sideMenu());
    compactLayout();

    window.onresize = () => {
      compactLayout();
    };
  }, [location, authUser?.role?.name, authUser?.user_type]);
  const isUnreadNotifications = notificationsBadge > 0;
  const isUnreadQueues = queuesBadge > 0;
  return (
    <div
      className={clsx([
        "razor background min-h-screen",
        "before:content-[''] before:bg-gradient-to-b before:from-slate-100 before:to-slate-50 before:h-screen before:w-full before:fixed before:top-0",
        "after:content-[''] after:fixed after:inset-0 after:bg-[radial-gradient(rgb(0_0_0_/_10%)_1px,_transparent_0)] after:bg-[length:25px_25px]",
      ])}
    >
      <div
        className={clsx([
          "xl:ml-0 shadow-xl transition-[margin] duration-300 xl:shadow-none fixed top-0 left-0 z-50 side-menu group inset-y-0 xl:py-3.5 xl:pl-3.5",
          "after:content-[''] after:fixed after:inset-0 after:bg-black/80 after:xl:hidden",
          { "side-menu--collapsed": compactMenu },
          { "side-menu--on-hover": compactMenuOnHover },
          { "ml-0 after:block": activeMobileMenu },
          { "-ml-[275px] after:hidden": !activeMobileMenu },
        ])}
      >
        <div
          className={clsx([
            "fixed ml-[275px] w-10 h-10 items-center justify-center xl:hidden z-50",
            { flex: activeMobileMenu },
            { hidden: !activeMobileMenu },
          ])}
        >
          <a
            href=""
            onClick={(event) => {
              event.preventDefault();
              setActiveMobileMenu(false);
            }}
            className="mt-5 ml-5"
          >
            <Lucide icon="X" className="w-8 h-8 text-white" />
          </a>
        </div>
        <div
          className={clsx([
            "h-full box border-transparent rounded-none xl:rounded-xl bg-gradient-to-b from-primary to-secondary z-20 relative w-[275px] duration-300 transition-[width] group-[.side-menu--collapsed]:xl:w-[91px] group-[.side-menu--collapsed.side-menu--on-hover]:xl:shadow-[6px_0_12px_-4px_#0000000f] group-[.side-menu--collapsed.side-menu--on-hover]:xl:w-[275px] overflow-hidden flex flex-col",
            "after:content-[''] after:absolute after:inset-0 after:-mr-4 after:bg-texture-white after:bg-contain after:bg-fixed after:bg-[center_-20rem] after:bg-no-repeat",
          ])}
          onMouseOver={(event) => {
            event.preventDefault();
            setCompactMenuOnHover(true);
          }}
          onMouseLeave={(event) => {
            event.preventDefault();
            setCompactMenuOnHover(false);
          }}
        >
          <div
            className={clsx([
              "pt-2 flex items-center z-10 px-5 h-[65px] xl:w-[275px] overflow-hidden relative duration-300 group-[.side-menu--collapsed]:xl:w-[91px] group-[.side-menu--collapsed.side-menu--on-hover]:xl:w-[275px]",
              "before:content-[''] before:absolute before:right-0 before:border-r before:border-dashed before:border-white/[0.15] before:h-4/6 before:group-[.side-menu--collapsed.side-menu--on-hover]:xl:hidden",
            ])}
          >
            <a
              href="/"
              className="flex items-center transition-[margin] duration-300 group-[.side-menu--collapsed]:xl:ml-4 group-[.side-menu--collapsed.side-menu--on-hover]:xl:ml-0"
            >
              <img src={logowhite} className="w-2/4" />
            </a>
            {/* <a
              href=""
              onClick={toggleCompactMenu}
              className="group-[.side-menu--collapsed.side-menu--on-hover]:xl:opacity-100 group-[.side-menu--collapsed]:xl:rotate-180 group-[.side-menu--collapsed]:xl:opacity-0 transition-[opacity,transform] hidden 3xl:flex items-center justify-center w-[20px] h-[20px] ml-auto border rounded-full border-white/40 text-white hover:bg-white/5"
            >
              <Lucide icon="ArrowLeft" className="w-3.5 h-3.5 stroke-[1.3]" />
            </a> */}
          </div>
          <div
            ref={scrollableRef}
            className={clsx([
              "w-full h-full z-20 px-5 overflow-y-auto overflow-x-hidden pb-3 [-webkit-mask-image:-webkit-linear-gradient(top,rgba(0,0,0,0),black_30px)] [&:-webkit-scrollbar]:w-0 [&:-webkit-scrollbar]:bg-transparent",
              "[&_.simplebar-content]:p-0 [&_.simplebar-track.simplebar-vertical]:w-[10px] [&_.simplebar-track.simplebar-vertical]:mr-0.5 [&_.simplebar-track.simplebar-vertical_.simplebar-scrollbar]:before:bg-slate-400/30",
            ])}
          >
            <ul className="scrollable pt-5">
              {/* BEGIN: First Child */}
              {formattedMenu.map((menu, menuKey) =>
                typeof menu == "string" ? (
                  <li className="side-menu__divider" key={menuKey}>
                    {menu}
                  </li>
                ) : (
                  <li key={menuKey}>
                    <a
                      href=""
                      className={clsx([
                        "side-menu__link",
                        { "side-menu__link--active": menu.active },
                        {
                          "side-menu__link--active-dropdown":
                            menu.activeDropdown,
                        },
                      ])}
                      onClick={(event: React.MouseEvent) => {
                        event.preventDefault();
                        linkTo(menu, navigate);
                        setFormattedMenu([...formattedMenu]);
                      }}
                    >
                      <Lucide
                        icon={menu.icon}
                        className="side-menu__link__icon"
                      />
                      <div className="side-menu__link__title">{menu.title}</div>
                      {menu.badge && (
                        <div className="side-menu__link__badge">
                          {menu.badge}
                        </div>
                      )}
                      {menu.subMenu && (
                        <Lucide
                          icon="ChevronDown"
                          className="side-menu__link__chevron"
                        />
                      )}
                    </a>
                    {/* BEGIN: Second Child */}
                    {menu.subMenu && (
                      <Transition
                        in={menu.activeDropdown}
                        onEnter={enter}
                        onExit={leave}
                        timeout={300}
                      >
                        <ul
                          className={clsx([
                            "",
                            { block: menu.activeDropdown },
                            { hidden: !menu.activeDropdown },
                          ])}
                        >
                          {menu.subMenu.map((subMenu, subMenuKey) => (
                            <li key={subMenuKey}>
                              <a
                                href=""
                                className={clsx([
                                  "side-menu__link",
                                  { "side-menu__link--active": subMenu.active },
                                  {
                                    "side-menu__link--active-dropdown":
                                      subMenu.activeDropdown,
                                  },
                                ])}
                                onClick={(event: React.MouseEvent) => {
                                  event.preventDefault();
                                  linkTo(subMenu, navigate);
                                  setFormattedMenu([...formattedMenu]);
                                }}
                              >
                                <Lucide
                                  icon={subMenu.icon}
                                  className="side-menu__link__icon"
                                />
                                <div className="side-menu__link__title">
                                  {subMenu.title}
                                </div>
                                {subMenu.badge && (
                                  <div className="side-menu__link__badge">
                                    {subMenu.badge}
                                  </div>
                                )}
                                {subMenu.subMenu && (
                                  <Lucide
                                    icon="ChevronDown"
                                    className="side-menu__link__chevron"
                                  />
                                )}
                              </a>
                              {/* BEGIN: Third Child */}
                              {subMenu.subMenu && (
                                <Transition
                                  in={subMenu.activeDropdown}
                                  onEnter={enter}
                                  onExit={leave}
                                  timeout={300}
                                >
                                  <ul
                                    className={clsx([
                                      "",
                                      {
                                        block: subMenu.activeDropdown,
                                      },
                                      { hidden: !subMenu.activeDropdown },
                                    ])}
                                  >
                                    {subMenu.subMenu.map(
                                      (lastSubMenu, lastSubMenuKey) => (
                                        <li key={lastSubMenuKey}>
                                          <a
                                            href=""
                                            className={clsx([
                                              "side-menu__link",
                                              {
                                                "side-menu__link--active":
                                                  lastSubMenu.active,
                                              },
                                              {
                                                "side-menu__link--active-dropdown":
                                                  lastSubMenu.activeDropdown,
                                              },
                                            ])}
                                            onClick={(
                                              event: React.MouseEvent
                                            ) => {
                                              event.preventDefault();
                                              linkTo(lastSubMenu, navigate);
                                              setFormattedMenu([
                                                ...formattedMenu,
                                              ]);
                                            }}
                                          >
                                            <Lucide
                                              icon={lastSubMenu.icon}
                                              className="side-menu__link__icon"
                                            />
                                            <div className="side-menu__link__title">
                                              {lastSubMenu.title}
                                            </div>
                                            {lastSubMenu.badge && (
                                              <div className="side-menu__link__badge">
                                                {lastSubMenu.badge}
                                              </div>
                                            )}
                                          </a>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </Transition>
                              )}
                              {/* END: Third Child */}
                            </li>
                          ))}
                        </ul>
                      </Transition>
                    )}
                    {/* END: Second Child */}
                  </li>
                )
              )}
              {/* END: First Child */}
            </ul>
          </div>
          <div className="flex flex-row items-center justify-between p-4 z-10">
            {crmVersion && (
              <div className="flex justify-start ">
                <p className="text-gray-300 text-[12px]">{`v ${crmVersion}`}</p>
              </div>
            )}

            <Tippy
              as="span"
              options={{ theme: "light" }}
              className="flex items-center"
              content={"Report an Issue"}
            >
              <Lucide
                icon="HelpCircle"
                color="white"
                className="w-[20px] h-[20px]"
                onClick={() => setRaiseTicket(true)}
              />
            </Tippy>
          </div>
        </div>
        <div
          className={clsx([
            "fixed h-[65px] transition-[margin] duration-100 xl:ml-[275px] group-[.side-menu--collapsed]:xl:ml-[90px] mt-3.5 inset-x-0 top-0",
            "before:content-[''] before:mx-5 before:absolute before:top-0 before:inset-x-0 before:-mt-[15px] before:h-[20px] before:backdrop-blur border-slate-300",
          ])}
        >
          <Card className="absolute left-0 xl:left-3.5 right-0 h-full mx-5 group transition-colors box ease-in-out duration-200 px-0 md:mr-8">
            <div className="flex items-center w-full h-full transition-[padding] ease-in-out duration-300 px-5">
              <div className="flex items-center gap-1 xl:hidden">
                <a
                  href=""
                  onClick={(event) => {
                    event.preventDefault();
                    setActiveMobileMenu(true);
                  }}
                  className="p-2 rounded-full hover:bg-slate-100"
                >
                  <Lucide icon="AlignJustify" className="w-[18px] h-[18px]" />
                </a>
                <a
                  href=""
                  className="p-2 rounded-full hover:bg-slate-100"
                  onClick={(e) => {
                    e.preventDefault();
                    setQuickSearch(true);
                  }}
                >
                  <Lucide icon="Search" className="w-[18px] h-[18px]" />
                </a>
              </div>
              {/* BEGIN: Breadcrumb */}
              <Breadcrumb className="flex-1 hidden xl:block">
                <Breadcrumb.Link to="/">Dashboard</Breadcrumb.Link>
                <Breadcrumb.Link to={currentScreen?.path} active={true}>
                  {currentScreen?.title}
                </Breadcrumb.Link>
              </Breadcrumb>
              {/* END: Breadcrumb */}
              {/* BEGIN: Search */}
              <div
                className="relative justify-center flex-1 hidden xl:flex"
                onClick={() =>
                  authUser?.user_type != "Superadmin" && setQuickSearch(true)
                }
              >
                <div
                  className="bg-slate-50 border w-[350px] flex items-center py-2 px-3.5 rounded-[0.5rem] text-slate-400
                 cursor-pointer hover:bg-slate-100 transition-colors duration-300 hover:duration-100"
                >
                  <Lucide icon="Search" className="w-[18px] h-[18px]" />
                  <div className="ml-2.5 mr-auto">Quick search...</div>
                  <div>⌘K</div>
                </div>
              </div>
              <QuickSearch
                quickSearch={quickSearch}
                setQuickSearch={setQuickSearch}
              />
              {/* END: Search */}
              {/* BEGIN: Notification & User Menu */}
              <div className="flex items-center flex-1">
                <div className="flex items-center mr-2 ml-auto">
                  {checkPermission(queryAPIUrls?.create) && !isSuperAdmin() ? (
                    <Button
                      as={Link}
                      to="/create-query"
                      variant="primary"
                      className="text-xs group-[.mode--light]:!bg-white/[0.12] group-[.mode--light]:!text-slate-200 group-[.mode--light]:!border-transparent mr-5"
                    >
                      <Lucide
                        icon="Plus"
                        className="stroke-[1.3] w-4 h-4 mr-2"
                      />
                      Create
                    </Button>
                  ) : null}
                  <a
                    href=""
                    className="p-2 rounded-full relative hover:bg-slate-100"
                    onClick={(e) => {
                      e.preventDefault();
                      authUser?.user_type != "Superadmin" &&
                        setNotificationsPanel(true);
                    }}
                  >
                    <Lucide icon="Bell" className="w-[18px] h-[18px]" />
                    {isUnreadNotifications && (
                      <LoadingIcon
                        icon="puff"
                        className="w-10 h-10 absolute left-[-0.15rem] top-[-0.2rem]"
                      />
                    )}
                    {isUnreadNotifications && (
                      <span
                        style={{ lineHeight: 1.2 }}
                        className="p-[0.7px] flex items-center justify-center text-center text-white rounded-full min-h-5 min-w-5 bg-red-500 absolute left-[90%] bottom-[60%]"
                      >
                        {notificationsBadge}
                      </span>
                    )}
                  </a>
                  {isManager() || isAdmin() ? (
                    <a
                      href=""
                      className="ml-5 p-2 rounded-full relative hover:bg-slate-100"
                      onClick={(e) => {
                        e.preventDefault();
                        authUser?.user_type != "Superadmin" &&
                          setQueryPanel(true);
                      }}
                    >
                      <img src={RecieptIcon} className="w-[18px] h-[18px" />
                      {/* <Lucide icon="Slip" className="w-[18px] h-[18px]" /> */}
                      {isUnreadQueues && (
                        <LoadingIcon
                          icon="puff"
                          className="w-10 h-10 absolute left-[-0.15rem] top-[-0.2rem]"
                        />
                      )}
                      {isUnreadQueues && (
                        <span
                          style={{ lineHeight: 1.2 }}
                          className="p-[0.7px] flex items-center justify-center text-center text-white rounded-full min-h-5 min-w-5 bg-red-500 absolute left-[90%] bottom-[60%]"
                        >
                          {queuesBadge}
                        </span>
                      )}
                    </a>
                  ) : null}
                </div>
                <Menu className="ml-5">
                  <Menu.Button className="flex items-center">
                    <div className="rounded-full w-[36px] h-[36px] border-[3px] border-slate-200/70 image-fit flex">
                      <Lucide icon="User" className="m-auto" />
                    </div>
                    <div className="ml-2">
                      {`${authUser?.name} ${authUser?.last_name || ""}`}
                    </div>
                  </Menu.Button>
                  <Menu.Items className="w-56 mt-1">
                    {/* <Menu.Item
                      onClick={() => {
                        // navigate("settings?page=security");
                      }}
                    >
                      <Lucide icon="Lock" className="w-4 h-4 mr-2" />
                      Change Password
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        // navigate("settings");
                      }}
                    >
                      <Lucide icon="Users" className="w-4 h-4 mr-2" />
                      Profile Info
                    </Menu.Item> */}
                    <Menu.Item
                      onClick={() => {
                        dispatch(logout());
                      }}
                    >
                      <Lucide icon="Power" className="w-4 h-4 mr-2" />
                      Logout
                    </Menu.Item>
                  </Menu.Items>
                </Menu>
              </div>
              <ActivitiesPanel
                activitiesPanel={activitiesPanel}
                setActivitiesPanel={setActivitiesPanel}
              />
              <NotificationsPanel
                visible={notificationsPanel}
                setVisible={setNotificationsPanel}
              />
              <QueryQueuesPanel
                visible={queryPanel}
                setVisible={setQueryPanel}
              />
              <SwitchAccount
                switchAccount={switchAccount}
                setSwitchAccount={setSwitchAccount}
              />
              {/* END: Notification & User Menu */}
            </div>
          </Card>
        </div>
      </div>
      <div
        className={clsx([
          "transition-[margin,width] duration-100 xl:pl-3.5 pt-[65px] pb-16 relative z-10",
          { "xl:ml-[275px]": !compactMenu },
          { "xl:ml-[91px]": compactMenu },
        ])}
      >
        <div className="px-5 mt-14">
          <div className="container">
            <Outlet />
          </div>
        </div>
      </div>

      <SlideoverModal
        size="lg"
        visible={raiseTicket}
        onClose={() => {
          setRaiseTicket(false);
        }}
        headerTitle={`Report an Issue`}
      >
        <div className="h-[100%]">
          <iframe
            src={`https://cytin.online/forms/ticket`}
            width="100%"
            height="100%"
          />
        </div>
      </SlideoverModal>
    </div>
  );
}

export default Main;

import DataTable from "../../../components/DataTable";
import AddUpdate from "./AddUpdate";
import { useEffect, useState } from "react";
import { fetchUsers } from "src/stores/user";
import { RootState } from "src/stores";
import { connect } from "react-redux";
import {
  createUpdateUserProps,
  userProps,
  userDataProps,
  locationProps,
} from "src/interfaces";
import { columns } from "./constants";
import { checkPermission } from "src/helpers";
import { locationAPIUrls, userAPIUrls } from "src/common/apiActions";
import { fetchLocations } from "src/stores/location";

const Component = ({
  fetchData,
  locations,
}: {
  fetchData: Function;
  locations: any;
}) => {
  const [isReload, setIsReload] = useState(false);
  const renderData = locations || [];
  const [visible, setVisible] = useState(false);
  const [model, setModel] = useState<any>({});

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <DataTable
        showColors={false}
        tableHeading="Locations"
        url={locationAPIUrls?.get}
        reload={isReload}
        onDataLoaded={() => setIsReload(false)}
        addBtnContent="Add New Location "
        onAddClick={
          checkPermission(locationAPIUrls?.create)
            ? () => {
                setVisible(true);
                setModel({});
              }
            : undefined
        }
        columns={columns({
          onEditClick: checkPermission(locationAPIUrls?.update)
            ? (row: locationProps) => {
                setModel({
                  id: row?.id,
                  name: row?.name,
                  short_name: row?.short_name,
                  email: row?.emails,
                  username: row?.username,
                  status: row?.status,
                  address: row?.address, // New field
                  postal_code: row?.postal_code, // New field
                  phones: row?.phones, // New field
                  emails: row?.emails, // New field
                  host: row?.host, // New field
                  port: row?.port, // New field
                  from_address: row?.from_address, // New field
                  reply_to_address: row?.reply_to_address, // New field
                  sender_name: row?.sender_name, // New field
                  ip_address: row?.ip_address, // New field
                  signature: row?.signature,
                  notification_phone: row?.notification_phone,
                  notification_email: row?.notification_email,
                });

                setVisible(true);
              }
            : undefined,
        })}
        data={renderData}
      />
      <AddUpdate
        visible={visible}
        setVisible={setVisible}
        model={model}
        setModel={setModel}
        onCreateUpdateComplete={(isError) => setIsReload(!isError)}
      />
    </>
  );
};

const mapStateToProps = ({ locations }: RootState) => ({ locations });

const mapDispatchToProps = (dispatch: any) => ({
  fetchData: () => dispatch(fetchLocations()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);

import React, { useEffect, useState } from "react";
import SlideoverModal from "../../../components/SlideoverModal";
import { FormHelp, FormInput, FormLabel } from "../../../base-components/Form";
import Lucide from "../../../base-components/Lucide";
import Button from "../../../base-components/Button";
import _ from "lodash";

import { createLocation, updateLocation } from "src/stores/location";
import { RootState } from "src/stores";
import { connect } from "react-redux";
import { locationProps } from "src/interfaces";
import ReactSelect from "src/base-components/ReactSelect";
import TinyMCE from "src/base-components/TinyMCE";

const Component = ({
  visible,
  setVisible,
  model = {} as any,
  setModel = () => {},
  onCreate,
  onUpdate,
  onCreateUpdateComplete,
}: {
  visible: boolean;
  setVisible: Function;
  model?: any;
  setModel?: Function;
  onCreate: (payload: locationProps) => void;
  onUpdate: (payload: locationProps) => void;
  onCreateUpdateComplete: Function;
}) => {
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setSubmitting(true);
    let payload: any = model;
    if (payload?.id) {
      Object?.keys(payload).map((key) => {
        if (!payload[key]) {
          delete payload[key];
        }
      });
      onUpdate({
        ...payload,
        callback: (isError: boolean) => {
          setVisible(!!isError);
          setSubmitting(false);
          onCreateUpdateComplete && onCreateUpdateComplete(isError);
        },
      } as any);
      return;
    }
    Object.keys(payload)?.map((key) => {
      if (!payload[key]) {
        delete payload[key];
      }
    });
    onCreate({
      ...payload,
      callback: (isError: boolean) => {
        setVisible(!!isError);
        setSubmitting(false);
        onCreateUpdateComplete && onCreateUpdateComplete(isError);
      },
    } as any);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event?.target?.name;
    const value = event?.target?.value;
    setModel({
      ...model,
      [name]: value,
    });
  };

  const statuses = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
    { label: "Suspended", value: "Suspended" },
  ];

  return (
    <SlideoverModal
      size="md"
      headerTitle={model?.id ? "Update Location" : "Create New Location"}
      visible={visible}
      onClose={(value) => {
        setVisible(value);
      }}
    >
      <form onSubmit={onSubmit} className="flex flex-col">
        <div className="w-full flex gap-2">
          <div className="w-1/2">
            <FormLabel>Location Name</FormLabel>
            <FormInput
              w-full
              onChange={onChange}
              value={model?.name || ""}
              required
              name="name"
              type="text"
              className="rounded-md"
              placeholder={"Enter name"}
            />
          </div>
          <div className="w-1/2">
            <FormLabel>Short Name</FormLabel>
            <FormInput
              w-full
              onChange={onChange}
              value={model?.short_name || ""}
              required
              name="short_name"
              type="text"
              className="rounded-md"
              placeholder={"Enter short name"}
            />
          </div>
        </div>

        <div className="w-full flex gap-2 mt-3">
          <div className="w-1/2">
            <FormLabel>Address</FormLabel>
            <FormInput
              w-full
              onChange={onChange}
              value={model?.address || ""}
              required
              name="address"
              type="text"
              className="rounded-md"
              placeholder={"Enter address"}
            />
          </div>
          <div className="w-1/2">
            <FormLabel>Postal Code</FormLabel>
            <FormInput
              w-full
              onChange={onChange}
              value={model?.postal_code || ""}
              required
              name="postal_code"
              type="text"
              className="rounded-md"
              placeholder={"Enter postal code"}
            />
          </div>
        </div>

        <div className="w-full flex gap-2 mt-3">
          <div className="w-1/2">
            <FormLabel>Phone</FormLabel>
            <FormInput
              w-full
              onChange={onChange}
              value={model?.phones || ""}
              required
              name="phones"
              type="text"
              className="rounded-md"
              placeholder={"Enter phone"}
            />
          </div>
          <div className="w-1/2">
            <FormLabel>Email</FormLabel>
            <FormInput
              w-full
              onChange={onChange}
              value={model?.emails || ""}
              required
              name="emails"
              type="email"
              className="rounded-md"
              placeholder={"Enter email"}
            />
          </div>
        </div>

        <div className="w-full flex gap-2 mt-3">
          <div className="w-1/2">
            <FormLabel>Host</FormLabel>
            <FormInput
              w-full
              onChange={onChange}
              value={model?.host || ""}
              required
              name="host"
              type="text"
              className="rounded-md"
              placeholder={"Enter host"}
            />
          </div>
          <div className="w-1/2">
            <FormLabel>Port</FormLabel>
            <FormInput
              w-full
              onChange={onChange}
              value={model?.port || ""}
              required
              name="port"
              type="text"
              className="rounded-md"
              placeholder={"Enter port"}
            />
          </div>
        </div>

        <div className="w-full flex gap-2 mt-3">
          <div className="w-1/2">
            <FormLabel>Username</FormLabel>
            <FormInput
              w-full
              onChange={onChange}
              value={model?.username || ""}
              required
              name="username"
              type="text"
              className="rounded-md"
              placeholder={"Enter username"}
            />
          </div>
          <div className="w-1/2">
            <FormLabel>Reply To Address</FormLabel>
            <FormInput
              w-full
              onChange={onChange}
              value={model?.reply_to_address || ""}
              required
              name="reply_to_address"
              type="email"
              className="rounded-md"
              placeholder={"Enter reply to address"}
            />
          </div>
        </div>

        <div className="w-full flex gap-2 mt-3">
          <div className="w-1/2">
            <FormLabel>Sender Name</FormLabel>
            <FormInput
              w-full
              onChange={onChange}
              value={model?.sender_name || ""}
              required
              name="sender_name"
              type="text"
              className="rounded-md"
              placeholder={"Enter sender name"}
            />
          </div>
          <div className="w-1/2">
            <FormLabel>Status</FormLabel>
            <ReactSelect
              required={true}
              options={statuses}
              onChange={(_: any, value) => {
                setModel((previous) => ({
                  ...previous,
                  status: value,
                }));
              }}
              value={model?.status}
              placeholder="Select Status"
            />
          </div>
        </div>

        <div className="mt-3">
          <FormLabel>IP Address</FormLabel>
          <FormInput
            w-full
            onChange={onChange}
            value={model?.ip_address || ""}
            name="ip_address"
            type="text"
            className="rounded-md"
            placeholder={"Enter IP address"}
          />
        </div>

        <div className="mt-3">
          <FormLabel className="font-bold ">
            Notification Configuration
          </FormLabel>
          <div className="w-full flex gap-2">
            <div className="w-1/2">
              <FormLabel>Notification Phone</FormLabel>
              <FormInput
                w-full
                onChange={onChange}
                value={model?.notification_phone || ""}
                required
                name="notification_phone"
                type="text"
                className="rounded-md"
                placeholder={"Enter Phone"}
              />
            </div>
            <div className="w-1/2">
              <FormLabel>Notification Email</FormLabel>
              <FormInput
                w-full
                onChange={onChange}
                value={model?.notification_email || ""}
                required
                name="notification_email"
                type="text"
                className="rounded-md"
                placeholder={"Enter Email"}
              />
            </div>
          </div>
        </div>

        <div className="w-full mt-3">
          <FormLabel>Signature</FormLabel>
          <div className={`${!model?.signature && "border border-gray-300"}`}>
            <TinyMCE
              value={model?.signature || ""}
              onEditorChange={(data) => {
                setModel((previous) => ({
                  ...previous,
                  signature: data,
                }));
              }}
            />
          </div>
        </div>
        <FormHelp className="text-right">
          Maximum characters: {model?.signature ? model.signature.length : 0}
          /2000
        </FormHelp>

        <div className="absolute bottom-0 right-0 py-5 px-7">
          <Button
            className="w-full px-10 md:w-auto text-white bg-primary mt-auto ml-auto"
            type="submit"
            disabled={submitting}
            loading={submitting}
          >
            <Lucide icon="Check" className="stroke-[1.3] w-4 h-4 mr-2 -ml-2" />
            Submit
          </Button>
        </div>
      </form>
    </SlideoverModal>
  );
};

const mapStateToProps = ({ roles, locations, authUser }: RootState) => ({
  roles,
  locations,
  authUser,
});

const mapDispatchToProps = (dispatch: any) => ({
  onCreate: (payload: any) => dispatch(createLocation(payload)),
  onUpdate: (payload: any) => dispatch(updateLocation(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);

import React, { ChangeEvent, useEffect, useState } from "react";
import SlideoverModal from "../../components/SlideoverModal";
import {
  FormHelp,
  FormInput,
  FormLabel,
  FormSelect,
} from "../../base-components/Form";
import {
  alertProps,
  createUpdateEmailTemplateProps,
  locationProps,
} from "src/interfaces";
import { connect } from "react-redux";
import {
  createEmailTemplate,
  updateEmailTemplate,
} from "src/stores/emailTemplate";
import { fetchPermissions } from "src/stores/permission";
import { RootState } from "src/stores";
import Button from "src/base-components/Button";
import Lucide from "src/base-components/Lucide";
import { templateTypes } from "./constants";
import { setAlert } from "src/stores/basicSlices";
import { fetchLocations } from "src/stores/location";
import LoadingIcon from "src/base-components/LoadingIcon";
import ReactSelect from "src/base-components/ReactSelect";
import TinyMCE from "src/base-components/TinyMCE";

const Component = ({
  visible,
  setVisible,
  model = {} as any,
  setModel = () => {},
  onCreate,
  onUpdate,
  fetchLocations,
  locations,
  setAlert,
  onCreateUpdateComplete,
}: {
  visible: boolean;
  setVisible: Function;
  setAlert: (payload: alertProps) => void;
  model?: createUpdateEmailTemplateProps;
  fetchLocations: Function;
  locations: locationProps[];
  setModel?: (payload: createUpdateEmailTemplateProps | any) => void;
  onCreate: (payload: createUpdateEmailTemplateProps) => void;
  onUpdate: (payload: createUpdateEmailTemplateProps) => void;
  onCreateUpdateComplete: Function;
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const locationOptions = locations.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  useEffect(() => {
    if (visible) {
      setLoading(true);
      fetchLocations({
        callback: () => {
          setLoading(false);
        },
      });
    }
  }, [visible]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (model?.location_id?.length == 0) {
      setAlert({
        text: "Please select template location",
        type: "danger",
        title: "Failed to create Template!",
      });
      return;
    }
    if (!model?.content) {
      setAlert({
        text: "Please enter template content",
        type: "danger",
        title: "Failed to create Template!",
      });
      return;
    }
    setSubmitting(true);
    let payload: any = model;
    if (payload?.id) {
      onUpdate({
        ...payload,
        callback: (isError: boolean) => {
          setVisible(!!isError);
          setSubmitting(false);
          onCreateUpdateComplete && onCreateUpdateComplete(isError);
        },
      } as any);
      return;
    }
    Object.keys(payload)?.map((key) => {
      if (!payload[key]) {
        delete payload[key];
      }
    });
    onCreate({
      ...payload,
      callback: (isError: boolean) => {
        setVisible(!!isError);
        setSubmitting(false);
        onCreateUpdateComplete && onCreateUpdateComplete(isError);
      },
    } as any);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setModel((previous) => {
      return { ...previous, [name]: value };
    });
  };

  return (
    <SlideoverModal
      size="lg"
      headerTitle={model?.id ? "Update Email Template" : "Add Email Template"}
      visible={visible}
      onClose={(value) => {
        setVisible(value);
      }}
    >
      <form onSubmit={onSubmit} className="h-full w-full flex flex-col">
        <div className="w-full">
          <FormLabel>Title</FormLabel>
          <FormInput
            value={model?.title}
            name="title"
            required
            type="text"
            onChange={onChange}
          />
        </div>
        <div className="w-full mt-3">
          <FormLabel>Type</FormLabel>
          <ReactSelect
            options={templateTypes.map((type) => ({
              value: type.value,
              label: type.label,
            }))}
            required={true}
            value={model?.type}
            placeholder="--Select--"
            onChange={(_: any, value) => {
              setModel((previous) => ({
                ...previous,
                type: value,
              }));
            }}
            isMulti={false}
          />
        </div>

        {model.id && (
          <div className="w-full mt-3">
            <FormLabel>Status</FormLabel>
            <ReactSelect
              options={[
                { value: "1", label: "Active" },
                { value: "0", label: "Inactive" },
              ]}
              value={model?.status}
              onChange={(_, value) => {
                setModel((previous) => ({
                  ...previous,
                  status: value,
                }));
              }}
            />
          </div>
        )}
        <div className="mt-2 ">
          <FormLabel>Location</FormLabel>
          {loading ? (
            <div className="flex w-[100%] justify-center py-2">
              <LoadingIcon
                color="#64748b"
                icon="oval"
                className="w-8 h-8 mx-auto"
              />
            </div>
          ) : (
            <div className="w-full">
              <ReactSelect
                options={locationOptions}
                onChange={(_: any, value) => {
                  setModel((previous) => ({
                    ...previous,
                    location_id: value,
                  }));
                }}
                value={model?.location_id}
                isMulti={true}
                placeholder="Please select office location."
              />
            </div>
          )}
        </div>

        <div className="w-full mt-3">
          <FormLabel>Content</FormLabel>
          <div className="border border-gray-300 rounded-md">
            <TinyMCE
              value={model?.content || ""}
              onEditorChange={(data) => {
                onChange({
                  target: {
                    name: "content",
                    value: data,
                  },
                } as any);
              }}
            />
          </div>
          <FormHelp className="text-right">
            {" "}
            Maximum characters: {model?.content ? model.content.length : 0}/2000
          </FormHelp>
        </div>
        <Button
          className="w-full px-10 md:w-auto text-white bg-primary mt-auto ml-auto"
          type="submit"
          disabled={submitting || loading}
          loading={submitting}
        >
          <Lucide icon="Check" className="stroke-[1.3] w-4 h-4 mr-2 -ml-2" />
          Submit
        </Button>
      </form>
    </SlideoverModal>
  );
};

const mapStateToProps = ({ permissions, locations }: RootState) => ({
  permissions,
  locations,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchPermissions: () => dispatch(fetchPermissions()),
  fetchLocations: (payload: any) => dispatch(fetchLocations(payload)),
  onCreate: (payload: any) => dispatch(createEmailTemplate(payload)),
  onUpdate: (payload: any) => dispatch(updateEmailTemplate(payload)),
  setAlert: (payload: any) => dispatch(setAlert(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
